import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { RoughNotationWrapper } from "../components/atoms";
import Layout, { mq } from "../components/layout";
import Header from "../components/Header";
import * as colors from "../utils/colors";
import ContactUs from "../components/ContactUs";

const SpanPerson = styled.span`
  display: inline;
  font-size: 1.17rem;
  font-weight: bold;
`;

const Person = ({ name, tagline, twitterHandle, image }) => (
  <div
    css={css`
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
    `}
  >
    <GatsbyImage
      image={image.childImageSharp.gatsbyImageData}
      alt={`${name}s profile picture`}
      draggable={false}
      css={css`
        border-radius: 1000px;
      `}
    />
    <a
      href={`https://twitter.com/${twitterHandle}`}
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        color: unset;
      `}
    >
      <h3>{name}</h3>
    </a>
    <p
      css={css`
        font-size: 1.1rem;
        font-weight: 300;
        margin: 0;
      `}
    >
      {tagline}
    </p>
  </div>
);

const AboutPage = ({ location }) => {
  const { niklas, niko, olle } = useStaticQuery(
    graphql`
      {
        niklas: file(relativePath: { eq: "profiles/niklas.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              height: 150
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        niko: file(relativePath: { eq: "profiles/niko.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              height: 150
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        olle: file(relativePath: { eq: "profiles/olle.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 150
              height: 150
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );

  return (
    <Layout
      preTitle="About us"
      description="This is who we are and why we are building Grapic."
      pathname={location.pathname}
      includeStructuredOrg
    >
      <Header />
      <div
        css={css`
          width: 100%;
          max-width: 90rem;
          margin: 0 auto;
          padding: 3rem 1rem 0 1rem;
          text-align: center;
          flex-grow: 1;
        `}
      >
        <h2>About us</h2>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            ${mq[1]} {
              flex-direction: row;
              padding: 0 6rem;
            }
            ${mq[2]} {
              padding: 0 10rem;
            }
          `}
        >
          <Person
            name={"Niko"}
            tagline={"Co-founder"}
            twitterHandle={"NikolausWest"}
            image={niko}
          />
          <Person
            name={"Olle"}
            tagline={"Co-founder"}
            twitterHandle={"olle_tingstam"}
            image={olle}
          />
          <Person
            name={"Niklas"}
            tagline={"Co-founder"}
            twitterHandle={"niklaslavrell"}
            image={niklas}
          />
        </div>
        <div
          css={css`
            font-size: 1.1rem;
            font-weight: 300;
            max-width: 45rem;
            margin: auto;
          `}
        >
          <p css={{ marginBottom: "3rem" }}>
            {`Product design by `}
            <a
              href={`https://www.hannasoderquist.se/?ref=grapic`}
              target="_blank"
              rel="noopener noreferrer"
              css={css`
                color: unset;
              `}
            >
              <SpanPerson>Hanna</SpanPerson>
            </a>
            {` and illustrations by `}
            <a
              href={`https://www.instagram.com/sannascribbles/`}
              target="_blank"
              rel="noopener noreferrer"
              css={css`
                color: unset;
              `}
            >
              <SpanPerson>Sanna</SpanPerson>
            </a>
            .
          </p>
          <p>
            When the world's most innovative and creative workers collaborate on
            their most important problems, they turn to simple analog tools like
            pen, paper and whiteboards.
          </p>
          <p>
            The current pandemic is making it clearer than ever that it is hard
            to collaborate when we aren't in the same room. We think the lack of
            these simple analog tools is one of the reasons for this.
          </p>
          <p>
            Our mission is to make remote knowledge work tools more adapted to
            humans.
          </p>
          <p>
            <RoughNotationWrapper
              type="highlight"
              show
              animationDelay={800}
              color={colors.GRAPIC_YELLOW}
              multiline
              css={css`
                font-size: 1.1rem;
                font-weight: 300;
              `}
            >
              {`Do you agree?`}
            </RoughNotationWrapper>
            {` `}
            <ContactUs />
          </p>
        </div>
      </div>
      <div
        css={css`
          margin: 0 auto;
        `}
      >
        <StaticImage
          src="../assets/illustrations/Rocket_Pen.png"
          alt="Rocket pen"
          width={540}
          formats={["auto", "webp", "avif"]}
          placeholder="tracedSVG"
        />
      </div>
    </Layout>
  );
};

export default AboutPage;
